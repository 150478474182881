
@import "../../assets/scss/index.scss";

.processitem{
    text-transform:uppercase;
    display: flex;
    align-items: center;
    margin: 20px 0 20px;
    font-size: 42px; 
    gap: 15px;
    color: var( --primary-color );
    @include  tab-sm() {
        margin: 20px 0 20px;
        display: flex;
        align-items: center;
    }
    figure{
        width: 72px;
        height:72px;
        display:flex;
        align-items: flex-start;
        justify-content:center;
        img{
            max-width: 100%;
        }
       
    }
    h4{
        font-size: 25px;
        font-weight: 600;
    }
    p{
        margin: 0;
 
    }

}
.be_creative{
    background-size:cover;
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: url("../../assets/images/banners/be_creative.png") no-repeat center center;
        @include transition(all 0.4s ease);
    }
    &:hover{
        &:before {
            filter: blur(5px);
            -webkit-filter: blur(5px);  
        }
        img{
            @include  transform(scale(1.1));
        }
    }
}
.be_creative_title{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    display: block;
    gap: 50px;
    color: var(--gray);
    position: relative;
    z-index: 2;
    @include  tab-sm() {
        font-size: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @include  tab-sm-max() {
        padding-top: 100px;
    }
    img{
        cursor: pointer;
        @include  transform(scale(1));
        @include transition(all 0.4s ease);
        @include  tab-sm-max() {
            position: absolute;
            left: calc(50% - 40px);
            top: 0;
            width: 80px;
        }
    }
}

.serviceImage{
    position: relative;
    margin-bottom: 25px;
    max-width: 475px;
    &:before{
        width: 80px;
        height: 80px;
        content: "";
        display: block;
        position: absolute;
        left: 20px;
        top: 10px;
        background: url("../../assets/images/quotes_icon.png") no-repeat center center;
        background-size: contain;
        z-index: 2;
    }
    &:after{
        width: 80px;
        height: 80px;
        content: "";
        display: block;
        position: absolute;
        right: 20px;
        bottom: 15px;
        background: url("../../assets/images/quotes_icon.png") no-repeat center center;
        background-size: contain;
        z-index: 2;
    }
    img{
        max-width: 100%;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        @include transition(all 0.3s ease); 
    }
    &:hover{
        img{
            filter: grayscale(0);
            -webkit-filter: grayscale(0);
        }
    }
}
.servicesList{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    @include  tab-sm() {
        gap: 20px;
    }
    li{
        width:100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: center;
        color: var(--white);
        font-size: 14px;
        border:  solid 1px var(--primary-color);
        @include border-radius(3px);
        @include  tab-sm() {
            font-size: 16px;
            padding: 0 15px;
            width:calc(50% -  30px);
        }
    }
}
.servicePic{
    display: flex;
    justify-content: flex-end;
    img{
        max-width: 100%;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        @include transition(all 0.3s ease); 
    }
    &:hover{
        img{
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
        }
    }
}