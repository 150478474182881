
@import "../../assets/scss/index.scss";

section .serviceText{
    text-align: center;
    margin-bottom:0;
    @include  desktop-sm() {
        margin:0 150px 0;
    }
    p{
        font-weight: 400;
        font-size: 19px;
        line-height: 37px;
        color: var(--textgray);
        @include  desktop-sm() {
            font-size: 29px;
            line-height: 47px; 
        }
    }
}
.services{
    display:block;
    margin-top: 60px;
    @include tab-md() {
        gap: 25px;
    }
    @include  desktop-sm() {
        display: flex;
        margin-top:100px;
        flex-wrap: wrap;
        justify-content: center;
    }
    @include desktop-xl() {
        gap: 45px;
    }
    
    .serviceItem{
        height:400px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        padding:25px 25px;
        margin-bottom:25px;
        @include border-radius(2px);
        @include tab-md() {
            width:30.9%;
        }
        @include  desktop-xl() {
            height:530px;
            width:30.3%;
            justify-content:center;
            padding-bottom: 35px;
        }
        &:after {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            z-index: 2;
            content: "";
            background:rgba(000, 000, 000, 0.6);
        }
        img{
            width: 100%;
            height: 100%;
            object-fit:cover;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            filter: grayscale(1);
            -webkit-filter: grayscale(1);
            @include transform(scale(1));
            @include transition(all 0.3s ease);
        }
        &:hover{
            img{
                @include transform(scale(1.1));
                filter: grayscale(0);
                -webkit-filter: grayscale(0);
            }
        }
        h2{
            font-size:23px;
            line-height:30px;
            font-weight: 600;
            color: var(--primary-color);
            position: relative;
            z-index: 3;
            text-align: center;
            text-transform: uppercase;
            @include  desktop-sm() {
                font-size: 28px;
                line-height:42px;
            }
        }
    }
    .location{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 3;
        text-align: center;
        padding:20px 30px;
        font-weight: 600;
        font-size: 11px;
        text-transform: uppercase;
        color: var(--textgray);
        background: var(--whitegray);
    }
}