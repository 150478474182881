// Fonts
$primary-font:  'Montserrat', sans-serif;
$secondary-font:  'Poppins', sans-serif;



// Colors
:root {
    --primary-color     : #F86833;
    --primary-font-color: var(--primary-color );
    --white             : #fff;
    --black             : #000;
    --gray              : #676767;
    --darkgray          : #545454;
    --textgray          : #777777;
    --lightgray         : #A3A3A3;
    --whitegray         : #D6D6D6;
}

