
@import "../../assets/scss/index.scss";

.processitem{
    text-transform:uppercase;
    display: flex;
    align-items: center;
    margin: 20px 0 20px;
    font-size: 42px; 
    gap: 15px;
    color: var( --primary-color );
    @include  tab-sm() {
        margin: 20px 0 20px;
        display: flex;
        align-items: center;
    }
    figure{
        width: 72px;
        height:72px;
        display:flex;
        align-items: flex-start;
        justify-content:center;
        img{
            max-width: 100%;
        }
       
    }
    h4{
        font-size: 25px;
        font-weight: 600;
    }
    p{
        margin: 0;
 
    }

}
.be_creative{
    background-size:cover;
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: url("../../assets/images/banners/be_creative.png") no-repeat center center;
        @include transition(all 0.4s ease);
    }
    &:hover{
        &:before {
            filter: blur(5px);
            -webkit-filter: blur(5px);  
        }
        img{
            @include  transform(scale(1.1));
        }
    }
}
.be_creative_title{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    display: block;
    gap: 50px;
    color: var(--gray);
    position: relative;
    z-index: 2;
    @include  tab-sm() {
        font-size: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @include  tab-sm-max() {
        padding-top: 100px;
    }
    img{
        cursor: pointer;
        @include  transform(scale(1));
        @include transition(all 0.4s ease);
        @include  tab-sm-max() {
            position: absolute;
            left: calc(50% - 40px);
            top: 0;
            width: 80px;
        }
    }
}

.serviceImage{
    position: relative;
    margin-bottom: 25px;
    max-width: 475px;
    &:before{
        width: 80px;
        height: 80px;
        content: "";
        display: block;
        position: absolute;
        left: 20px;
        top: 10px;
        background: url("../../assets/images/quotes_icon.png") no-repeat center center;
        background-size: contain;
        z-index: 2;
    }
    &:after{
        width: 80px;
        height: 80px;
        content: "";
        display: block;
        position: absolute;
        right: 20px;
        bottom: 15px;
        background: url("../../assets/images/quotes_icon.png") no-repeat center center;
        background-size: contain;
        z-index: 2;
    }
    img{
        max-width: 100%;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        @include transition(all 0.3s ease); 
    }
    &:hover{
        img{
            filter: grayscale(0);
            -webkit-filter: grayscale(0);
        }
    }
}
.servicesList{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:10px;
    margin-top: 20px;
    @include  tab-sm() {
        gap: 20px;
    }
    li{
        width:100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: center;
        color: var(--white);
        cursor: pointer;
        font-size: 14px;
        border:  solid 1px var(--primary-color);
        @include border-radius(3px);
        @include transition(all 0.4s ease);
        @include  tab-sm() {
            width:calc(33% - 30px);
            font-size: 16px;
            padding: 0 15px;
        }
        &:hover{
            background: var(--primary-color);
            color: var(--white);
            border-color: var(--primary-color);
        }
    }
}
.servicePic{
    display: flex;
    justify-content: flex-end;
    @include  tab-sm() {
        padding: 60px 0 0 50px;
    }
    img{
        max-width: 100%;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        @include transition(all 0.3s ease); 
    }
    &:hover{
        img{
            filter: grayscale(0);
            -webkit-filter: grayscale(0);
        }
    }
}

.services{
    display:block;
    margin-top: 60px;;
    @include  desktop-sm() {
        display: flex;
        gap: 45px;
        margin-top:100px;
    }
    .serviceItem{
        height:300px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items:center;
        justify-content:flex-end;
        flex-direction:column;
        padding:25px 25px;
        margin-bottom:25px;
        @include border-radius(7px);
        @include  desktop-sm() {
            height:500px;
            width:33.3%;
            padding-bottom: 35px;
        }
        &:after {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            z-index: 2;
            content: "";
            background:rgba(000, 000, 000, 0.6);
        }
        img{
            width: 100%;
            height: 100%;
            object-fit:cover;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            filter: grayscale(1);
            -webkit-filter: grayscale(1);
            @include transform(scale(1));
            @include transition(all 0.3s ease);
        }
        &:hover{
            img{
                @include transform(scale(1.1));
                filter: grayscale(0);
                -webkit-filter: grayscale(0);
            }
        }
        h2{
            font-size:23px;
            line-height:30px;
            font-weight: 600;
            color: var(--primary-color);
            position: relative;
            z-index: 3;
            text-transform: uppercase;
            @include  desktop-sm() {
                font-size: 33px;
                line-height:40px;
            }
        }
    }
    .action{
        width: 100%;
        position: relative;
        z-index: 3;
        text-align: center;
    }
}