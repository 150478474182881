@import "../../assets/scss/index.scss";

section {
    .tradingText {
        text-align: center;
        margin-bottom: 0;
        @include desktop-sm() {
            margin: 0 70px 0;
        }
        p {
            font-weight: 400;
            font-size: 19px;
            line-height: 37px;
            color: var(--textgray);
            @include desktop-sm() {
                font-size: 24px;
                line-height: 47px;
            }
        }
    }
}
.trading {
    display: block;
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    @include desktop-sm() {
        gap: 30px;
        justify-content: center;
        margin-top: 50px;
    }
    .tradingItem {
        height:80px;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        @include border-radius(6px);
        @include desktop-sm() {
            padding-bottom: 20px;
            height: 350px;
        }
        img{
            max-width: 100%;
            filter: grayscale(1);
            -webkit-filter: grayscale(1);
            @include transition(all 0.3s ease); 
        }
        &:hover{
            img{
                filter: grayscale(0);
                -webkit-filter: grayscale(0);
            }
        }
    }
}

.serviceImage{
    position: relative;
    margin-bottom: 25px;
    max-width: 475px;
    margin-left: auto;
    &:before{
        width: 80px;
        height: 80px;
        content: "";
        display: block;
        position: absolute;
        left: 20px;
        top: 10px;
        background: url("../../assets/images/quotes_icon.png") no-repeat center center;
        background-size: contain;
        z-index: 2;
    }
    &:after{
        width: 80px;
        height: 80px;
        content: "";
        display: block;
        position: absolute;
        right: 20px;
        bottom: 15px;
        background: url("../../assets/images/quotes_icon.png") no-repeat center center;
        background-size: contain;
        z-index: 2;
    }
    img{
        max-width: 100%;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        @include transition(all 0.3s ease); 
    }
    &:hover{
        img{
            filter: grayscale(0);
            -webkit-filter: grayscale(0);
        }
    }
}

.servicesList{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    @include  tab-sm() {
        gap: 20px;
    }
    li{
        width:100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: center;
        color: #777777;
        border:  1px solid #777777;
        cursor: pointer;
        outline: none;
        font-size: 14px;
        @include border-radius(3px);
        @include transition(all 0.4s ease);
        @include  tab-sm() {
            font-size: 16px;
            padding: 0 15px;
            width:calc(50% - 30px);
        }
    }
}