
@import "../../assets/scss/index.scss";


.companySection{
    height:auto;
    
    padding: 25px 0 50px;
    display:flex;
    align-items:center;
    color: var(--primary-color);
    
    position: relative;
    &::after{
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: url("../../assets/images/companyBg.jpg") no-repeat center left;
        background-attachment: fixed;
        background-size: cover;
        filter: grayscale(100%);
        @include transition(all 0.3s ease);
        @include  desktop-sm() {
            background: url("../../assets/images/companyBg.jpg") no-repeat center center;
        }
    }
    &:hover::after{
        filter: grayscale(0%);
    }
    .container{
        position: relative;
        z-index: 1;
    }
    @include  desktop-sm() {
        // height: 0px;
        padding: 50px 0;
    }
    
    }


    .companySection:hover {
        filter: grayscale(0%);
        
      }
    .successSection{
        display: block;
        @include  desktop-sm() {
            display:flex;
            gap: 20px;
            align-items:center;
            justify-content:center;
        }
        .item{
            width: 100%;
            text-align: center;
            @include  desktop-sm() {
                width: 30%;
            }
            &.border{
                border-top: solid 2px #D9D9D9;
                border-bottom: solid 2px #D9D9D9;
                padding: 25px 0;
                margin: 25px 0;
                @include  desktop-sm() {
                    border: none;
                    border-left: solid 2px #D9D9D9;
                    border-right: solid 2px #D9D9D9;
                }
            }
        }
        h3{
            font-size: 98px;
            font-weight: 600;
            font-family: $primary-font;
            color: var(--white);
            @include  desktop-sm() {
                font-size: 138px;
            }
        }
        p{
            font-weight: bold;
            font-size: 22px;
            font-family: $primary-font;
            color: var(--primary-color);
            max-width: 215px;
            margin: 0 auto;
            text-transform: uppercase;
            position: relative;
            @include  desktop-sm() {
                font-size: 32px;
                content: "";
                
            }
        }
    }
