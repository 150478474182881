
@import "../../assets/scss/index.scss";

.innerBanner{
    border-top: 90px  solid #363636; 
    height: 500px;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    position: relative;
    @include  desktop-sm() {
        border-top: 120px solid #363636; 
    }
    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit:cover;
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        z-index: 1;
        @include transition(all 0.3s ease); 
    }
    &:hover{
        img{
            filter: grayscale(0);
            -webkit-filter: grayscale(0);
        }
    }
    &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 2;
        background: rgba(000, 000, 000, 0.50);
    }
    .bannertext{
        text-align:center;
        color: var(--white);
        position: relative;
        z-index: 3; 
    }
    h1{
        font-weight: 500;
        font-size: 43px;
        line-height: 57px;
        font-family: $primary-font;
        margin: 0 0 10px 0;
        @include  desktop-sm() {
            font-size: 63px;
            line-height: 77px;
        }
    }
    p{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        font-family: $primary-font;
        margin: 0;
        @include  desktop-sm() {
            font-size: 21px;
            line-height: 26px;
        }
        a{
            text-decoration: none;
            color:var(--white);
            @include transition(all 0.3s ease);
            &:hover{
                color:var(--primary-color);
            }
        }
    }
}

.whatwedo_bg, .services_bg, .trading_bg,  .about_bg, .portfolio_bg, .testimonial_bg ,.advisory_bg{
    .innerBanner{
        filter: grayscale(1);
        -webkit-filter: grayscale(1);
        z-index: 1;
        @include transition(all 0.3s ease); 
    }
    &:hover{
        .innerBanner{
            filter: grayscale(0);
            -webkit-filter: grayscale(0);
            z-index: 1;
            @include transition(all 0.3s ease); 
        }
    }
}
        