
@import "../../assets/scss/index.scss";

.bdrLeft{
    @include  desktop-sm() {
        border-left: solid 4px var( --primary-color);
        padding-left: 70px;
    }
}
.vision_value{
    display:block;
    @include  desktop-sm() {
        display: flex;
        gap: 60px;
    }
    .vision_value_item{
        display:flex;
        gap: 35px;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        height:390px;
        padding: 22px;
        border: solid 3px var( --primary-color);
        margin-bottom: 25px;
        font-weight: 500;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        position: relative;
        @include border-radius(7px);
        @include  desktop-sm() {
            height:440px;
            width: 33%;
            border: solid 4px var( --primary-color);
        }
        .overtext{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            padding: 10px 20px;
            background: var( --primary-color);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            font-size: 20px;
            line-height: 36px;
            color: var(--white);
            opacity: 0;
            visibility: hidden;
            @include transition(all 0.3s ease); 
        }
        &:hover{
            .overtext{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    h3{
        font-weight: 600;
        font-size:40px;
        text-transform:uppercase;
        color:var(--darkgray);
        @include  desktop-sm() {
            font-size:47px;
        }
    }
}

.clientLogos{
    display:flex;
    flex-wrap: wrap;
    figure{
        width: 33.3%;
        height:100px;
        display:flex;
        align-items:center;
        padding: 10px;
        justify-content:center;
        border-right: solid 1px #D9D9D9;
        border-bottom: solid 1px #D9D9D9;
        &:nth-child(3n), &:last-child{
            border-right: none;
        }
        &:nth-child(4n),  &:nth-child(5n), &:nth-child(6n){
            border-bottom: none;
        }
        img{
            max-width: 100%;
            height: 100%;
            display: block;
        }
    }
}
.ourClientsBg{
    background: url("../../assets/images/banners/our_clients_bg.webp") no-repeat center center;
    background-attachment: fixed;
    background-size:cover;
    
}

section .serviceText{
    text-align: center;
    margin-bottom:0;
    @include  desktop-sm() {
        margin:0 0 0;
    }
    p{
        font-weight: 400;
        font-size: 19px;
        line-height: 37px;
        color: var(--textgray);
        @include  desktop-sm() {
            font-size: 24px;
            line-height: 47px; 
        }
    }
}