
@import "../../assets/scss/index.scss";
.succeessSection{
    height:400px;
    padding: 25px 0;
    display:flex;
    align-items:center;
    position: relative;
    &::after{
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: url("../../assets/images/banners/successful_bg.png") no-repeat center center;
        background-attachment: fixed;
        background-size: cover;
        filter: grayscale(100%);
        @include transition(all 0.3s ease);
    }
    &:hover::after{
        filter: grayscale(0%);
    }
    .container{
        position: relative;
        z-index: 1;
    }
    @include  desktop-sm() {
        height: 450px;
        padding: 50px 0;
    }
    .successSection{
        display:flex;
        gap: 20px;
        align-items:flex-end;
        justify-content:center;
        .years{
            font-size: 200px;
            font-weight: 600;
            margin: 0;
            color: var(--primary-color );
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
                max-width: 600px;
            }
            @include  desktop-sm() {
                font-size: 400px;
                margin-bottom: -30px;
            }
        }
        p{
            margin: 0 0 48px;
            font-weight: 700;
            font-size: 15px;
            line-height: 30px;
            color: var(--white);
            @include  desktop-sm() {
                font-size: 25px;
                line-height: 40px;
                margin-bottom: 92px;
                max-width: inherit;
            }
            span{
                display: block;
                color: var(--primary-color );
                @include  desktop-sm() {
                    white-space: nowrap;
                }
            }
        }
    }
}