
@import "../../assets/scss/index.scss";

.serviceImage{
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        display: block;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
        object-fit: cover;
        height: 100%;
        @include transition(all 0.4s ease);
    }
    &:hover{
        img{
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
        }
    }
}
.serviceDescription{
    display:flex;
    align-items:flex-start;
    flex-direction: column;
    justify-content:center;
    text-align: justify;
    height: 100%;
   
    padding:30px 0;
    
    
    p{

        font-weight: 400;

         line-height:30px;

    }
  
    @include  tab-sm() {
        padding:30px 60px;
    }
    @include  desktop-sm() {
        padding:40px  70px; 
    }
}


.be_creative{
    background-size:cover;
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: url("../../assets/images/banners/be_creative.png") no-repeat center center;
        @include transition(all 0.4s ease);
    }
    &:hover{
        &:before {
            filter: blur(5px);
            -webkit-filter: blur(5px);  
        }
        img{
            @include  transform(scale(1.1));
        }
    }
}
.be_creative_title{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    display: block;
    gap: 50px;
    color: var(--gray);
    position: relative;
    z-index: 2;
    @include  tab-sm() {
        font-size: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @include  tab-sm-max() {
        padding-top: 100px;
    }
    img{
        cursor: pointer;
        @include  transform(scale(1));
        @include transition(all 0.4s ease);
        @include  tab-sm-max() {
            position: absolute;
            left: calc(50% - 40px);
            top: 0;
            width: 80px;
        }
    }
}