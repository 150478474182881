@import "../../assets/scss/index.scss";

.sliderItem{
    position: relative;
    h4{
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
        color: #7A7A7A;
        text-transform: uppercase;
        text-align: right;
        position: relative;
        margin-bottom: 50px;
        margin-top: 30px;
        span{
            color: var(--primary-color);
        }
        @include  tab-sm() {
            font-size: 32px;
            line-height: 40px;
            margin-top: 10px;
        }
    }
}
.clientLogos{
    display:flex;
    flex-wrap: wrap;
    figure{
        width:33%;
        max-width: 215px;
        height:100px;
        display:flex;
        align-items:center;
        padding: 10px 20px; 
        justify-content:center;
        border-right: solid 1px #D9D9D9;
        border-bottom: solid 1px #D9D9D9;
        &:nth-child(3n), &:last-child{
            border-right: none;
        }
        &:nth-child(10n), &:nth-child(11n), &:last-child{
            border-bottom: none;
        }
        img{
            max-width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            transition: all 0.2s;
           -webkit-transition: all 0.2s;
           -moz-transition: all 0.2s;
           filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
          -moz-filter: grayscale(100%);
        }
        img:hover {
            filter: grayscale(0%);
            -webkit-filter: grayscale(0%);
            -moz-filter: grayscale(0%);
            transform: scale(1.05);
            -webkit-transform: scale(1.05);
            -moz-transform: scale(1.105);
        }

        @include  tab-sm() {
            width: 16%;
            max-width: 215px;
            padding: 10px 20px; 
            &:nth-child(6n), &:last-child{
                border-right: none;
            }
            &:nth-child(7n), &:nth-child(8n), &:nth-child(9n), &:nth-child(10n), &:nth-child(11n), &:last-child{
                border-bottom: none;
            }
            &:nth-child(3), &:nth-child(9){ 
                border-right: solid 1px #D9D9D9;
            }
        }
    }
}