input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: $primary-font;
  background: var(--white);
}

input, button, input:focus, button:focus{
  outline: none;
}

*, body {
  margin: 0;
  padding: 0;
  @include box-sizing(border-box);
}

input, button, input:focus, button:focus{
  outline: none;
}

.text-center{
  text-align: center;
}


.w-100{
  width: 100%;
}

// Margin

.m-auto{
  margin: 0 auto;
}
.mt-30{
  margin-top: 30px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-50{
  margin-bottom: 50px;
}

// Padding

.pt-50{
  padding-top: 50px;
}
.pt-0{
  padding-top: 0 !important;
}

// Layout

.container {
  padding: 0 25px;
  max-width: 1400px;
  margin: 0 auto; 
  width: 100%;
  @include tab-md() {
    padding: 0 15px;
    max-width: 1200px;
  }
  @include desktop-lg() {
    max-width: 1300px;
  }
}
.container-mini {
  padding: 0 25px;
  max-width: 1270px;
  margin: 0 auto; 
  width: 100%;
  @include  desktop-sm() {
    padding: 0 15px;
  }
}

.section{
  padding:60px 0;
  &.small{
    padding:40px 0;
  }
  
  @include  tab-sm() {
    padding:100px 0;
    &.small{
      padding:80px 0;
    }
  }
  p{
    font-size: 14px;
    line-height:32px;
    font-weight: 600;
    margin-bottom: 20px;
    color:var(--textgray);
    @include  tab-sm() {
      font-size: 16px;
      line-height:32px;
    }
        
    &.primary_text{
      color: var(--primary-color); 
    }
    &.mb-0{
      margin-bottom: 0;
    }
  }
  &.dark{
    background: var(--black);
  }
  &.gray{
    background: #F4F4F4;
  }
  &.popup{
    padding-bottom: 0;
  }
  &.pb-0{
    padding-bottom: 0;
  }
}
.whiteSection{
  background: #fff;
  padding-bottom: 120px;
  margin-top: 120px;
}

.flex-wrapper{
  display:block;
  &.reverse-mobile{
    @include tab-sm-max() {
      flex-direction: column-reverse;
      display: flex;
    }
  }
  @include  tab-sm() {
    padding: 0 15px;
    display:flex;
    gap: 40px;
    &.g-0{
      gap: 0;
    }
    .w-25-p{
      width: 25%;
    }
    .w-40-p{
      width: 45%;
    }
    .w-50-p{
      width: 50%;
    }
    .w-60-p{
      width: 55%;
    }
  }
}

.whatwedo_bg{
  .innerBanner{
      background: url("../../../assets/images/banners/what_we_do_banner.webp") no-repeat center center;
  }
}
.about_bg{
  .innerBanner{
      background: url("../../../assets/images/banners/about_banner.webp") no-repeat center center;
  }
}
.portfolio_bg{
  .innerBanner{
      background: url("../../../assets/images/banners/portfolio_banner.webp") no-repeat center center;
  }
}
.testimonial_bg{
  .innerBanner{
      background: url("../../../assets/images/banners/testimonial_bg.webp") no-repeat center center;
  }
}
.services_bg{
  .innerBanner{
      background: url("../../../assets/images/banners/services_banner.webp") no-repeat center center;
  }
}
.trading_bg{
  .innerBanner{
    background: url("../../../assets/images/banners/trading_banner_bg.webp") no-repeat center center;
  }
}
.advisory_bg{
  .innerBanner{
    background: url("../../../assets/images/banners/advisory.png") no-repeat center center;
  }
}
.aim_bg{
  background: url("../../../assets/images/aim-bg.png") no-repeat center left;
}

.error_page{
  position: relative;
  z-index: 10;
  background:#000 url("../../../assets/images/banners/error_page_bg.webp") no-repeat center center; 
  .container-mini *{
    margin: 25px auto;
    color: #fff;
    position: relative;
    z-index: 2;
    opacity: 0.8;
  } 
  h1{
    line-height: normal;
    font-size: 140px;
  }
  h2{
    font-size: 58px;
    font-weight: 500;
  }
  &:after{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    z-index: 1;
    background: rgba(000, 000, 000, 0.5);
  }
}

.bullet_list_style{
  list-style: none;
  margin: 0;
  padding: 0;
  li{
    padding-left: 15px;
    position: relative;
    font-size: 14px;
    line-height:24px;
    font-weight: 600;
    color:var(--textgray);
    @include  tab-sm() {
      font-size: 16px;
      line-height:32px;
    }
    &:after{
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      left: 0;
      top: 12px;
      display: block;
      background: var(--primary-color);
      @include border-radius(6px);
    }
  }
}

.ReactModal__Body--open{
  overflow: hidden;
  .ReactModal__Overlay{
    z-index: 999;
  }
  .popupClose{
    width: 23px;
    height: 23px;
    background: url("../../images/close-gray.svg") no-repeat center center;  
    text-indent: -9999px;
    cursor: pointer;
    border: none;
    position: absolute;
    right: 30px;
    top: 30px;
    overflow: hidden;
    @include transition(all 0.5s ease);
    &:hover{
      @include transform(rotate(180deg));
    }
    @include desktop-sm() {
      width: 43px;
      height: 43px;
    }
  }
}
.react-tabs{
  li.react-tabs__tab--selected{
    background: var(--primary-color);
    color: var(--white);
    outline: none;
    border-color: var(--primary-color);
  }
}

.headWrap{
  top: -65px;
  z-index: 15;
  @include transition(all 0.3s ease);
  

  &.static{
    top: 0px; 
    position: sticky;
    .stickyHead{
      background: #363636; 
      padding: 5px 0; 
    }
  }
}