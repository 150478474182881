

// Banner Slider
.slider-wrapper{
    overflow:hidden;
    position: relative;
    z-index: 1; 
    .slick-list{
        .slick-track{
            display:flex;
            align-items:center;
        }
        .slick-slide {
            min-height: auto; 
            @include  desktop-sm() {
                min-height: calc(100vh - 70px); 
            }
            img{
                width:100%;
                height:650px;
                object-fit:cover;
                display: block;
                object-position: center;
                @include  desktop-sm() {
                    height:100%;
                    min-height: 100vh;
                }
            }
        }
    }
    .slick-arrow {
        position:absolute;
        background: transparent;
        cursor: pointer;
        border:none;
        bottom:12px; 
        width:auto;
        height: 35px;
        z-index: 1;
        background-size: 33px;
        color: rgba(255, 255, 255, 0.50);
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        font-family: $primary-font;
        border:none;
        @include transition(all 0.3s ease);
        &:hover{
            color: rgba(255, 255, 255, 0.95);
            border-color: var(--primary-color);
        }
        @include  tab-sm() {
            bottom:25px; 
        }
        @include  desktop-sm() {
            width: auto;
            height: 35px;
            background-size: 43px;
            bottom:240px; 
        }
        &.slick-prev{
            left:7px;
            opacity: 0;
            visibility: hidden;
            @include  tab-sm() {
                right:150px;
                left:inherit;
                opacity: 1;
                visibility:visible;
                text-align: right;
                padding-left: 40px;
                background: url('../../images/arrow-prev.svg') no-repeat left center;
            }
            @include  desktop-sm() {
                right:160px;
                left:inherit;
            }
        }
        &.slick-next{
            right:7px;
            opacity: 0;
            visibility: hidden;
            @include  tab-sm() {
                right:20px;
                opacity: 1;
                visibility:visible;
                text-align: left;
                padding-right: 40px;
                background: url('../../images/arrow-next.svg') no-repeat right center;
            }
            @include  desktop-sm() {
                right:55px;
                left:inherit;
            }
        }
        &:hover{
            opacity:1;
        }
    }
  }

  // Testimonials Slider
  .testimonials-slider{
    overflow:hidden;
    position: relative;
    z-index: 2; 
    .slick-list{
        .slick-track{
            display:flex;
        }
    }
    .slick-arrow {
        display:none;
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }
  }
.clients-slider{
    overflow:hidden;
    position: relative;
    z-index: 2; 
    .slick-list{
        .slick-track{
            display:flex;
        }
    }
    .slick-arrow {
        display:none;
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }
    .slick-dots{
        text-align: center;
        margin-top: 50px;
        li{
            display: inline-flex;
            width: 22px;
            height: 22px;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
            @include transition(all 0.3s ease);
            button{
                width: 9px;
                height: 9px;
                display: block;
                border: none;
                background: var(--primary-color); 
                text-indent: -9999px;
                cursor: pointer;
            }
            &.slick-active{
                border: 1px solid #000000;
            }
        }
    }
}