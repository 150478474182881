
@import "../../assets/scss/index.scss";
.contact{
    max-width: 1000px;
    margin: 0 auto;
    .formDual{
        @include  desktop-sm() {
            display: flex;
            gap: 40px;
        }
    }
    .formSubmitWrap{
        display: flex;
        align-items: center;
        justify-content:center;
        margin-top:60px;
    }
}