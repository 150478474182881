
@import "../../assets/scss/index.scss";

.header{
    padding:5px 0;  
    position:absolute;
    left:0;
    top:0;
    width:100%;
    z-index: 10;
    @include transition(all 0.2s ease);
    @include  desktop-sm() {
        padding:15px 0;  
    }
    .logo{
        display: block;
        width: 123px;
        height:50px;
        object-fit:contain; 
        img{
            width: 100%;
            height: 100%;
        }
    }
}
.headerWrap{
    display:flex;
    align-items:center;
    justify-content:space-between;
    .navWrap{
        display:flex;
        align-items:center;
        justify-content:space-between;
        button{
            background: transparent;
            border: none;
            cursor: pointer;
            img{
                margin-right: -15px;
                filter: brightness(1);
                opacity: 0.7;
                @include transition(all 0.3s ease);
                
            }
            &:hover img{
                opacity: 1;
                filter:  brightness(200%);
            }
            @include tab-sm-max() {
                @include transform(scale(0.95));
            }
        }
    }
    nav{
        display: none;
        @include  desktop-sm() {
            display: flex;
            align-items: center;
            padding-right: 10px;
            margin-right: 20px;
            height:55px;
            border-right: solid 1px #D9D9D9;
        }
        a{
            font-size:16px;
            text-transform:uppercase;
            color: var(--white);
            margin: 0 30px;
            text-decoration: none;
            font-weight: 500;
            @include transition(all 0.3s ease);
            &:hover{
                color: var(--primary-color);
            }
            &.active{
                color: var(--primary-color);
                font-weight: 600;
            }
        }
    }
}

div.popupMenuOpen{
    right: 0;
}
.popupMenu, .popupMenuOpen{
    background: var(--black);
    padding: 70px 0 30px;
    max-width:420px;
    width: 100%;
    position:fixed;
    top: 0;
    right: -100%;
    display: block;
    z-index: 54;
    height: 100vh;
    @include transition(all 0.3s ease);
    @include  desktop-sm() {
        padding: 90px 0 30px;
    }
    .popupWrap{
        overflow:auto;
        text-align: center;
        height: 100%;
        max-height: calc(100vh - 112px); 
        padding: 0 30px;
        -ms-overflow-style: none;  
        scrollbar-width: none; 
        &::-webkit-scrollbar {
            display: none;
        }
        @include  desktop-sm() {
            padding: 0 50px;
        }
    }
    .close{
        width:50px;
        height: 50px;
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        display: block;
        cursor: pointer;
        background:rgba(255,255, 255, 0.03) url("../../assets/images/close-btn.png") no-repeat center center;
        background-size: 20px;
        text-indent: -9999px;
        @include transition(all 0.3s ease);
        @include  desktop-sm() {
            width: 70px;
            height:70px;
            background-size: 33px;
        }
        &:hover{
            // @include transform(rotate(90deg));
            background:rgba(255,255, 255, 0.2) url("../../assets/images/close-btn.png") no-repeat center center;
            background-size: 33px;
        }
    }
    .popupLogo{
        width: 100%;
        padding: 0 25px;
        margin-bottom: 30px;
        img{
            max-width: 100%;
        }
        @include  desktop-sm() {
            padding: 0 0;
            margin-bottom:50px;
        }
    }
    h3{
        font-size: 25px;
        text-align:center;
        font-weight: 400;
        color: var(--white);
        text-transform: uppercase;
        margin-bottom: 30px;
        @include  desktop-sm() {
            font-size: 30px;
        }
    }
    .connectus{
        display:flex;
        gap:rem(20px);
        justify-content: center;
        margin-bottom: 50px;
        figure{
            width: 120px;
            height: 120px;
            min-width: 120px;
            img{
                max-width: 100%;
            }
        }
        .connecttxt{
            font-size:13px;
            color: var(--white);
            text-align: center;
            line-height:22px;
            a{
                display: block;
                margin-top: 5px;
                color: var(--white);
                text-decoration: none;
                @include transition(all 0.3s ease);
                &:hover{
                    color: var(--primary-color);
                }
            }
        }
    }
    .brochureImage{
        max-width: 200px;
        margin: 0 auto;
        img{
            max-width:100%;
            display: block;
        }
    }
    .hidemobileView{
        display:none;
        @include  desktop-sm() {
            display: block;
        }
    }
    .menu{
        display:block;
        margin-bottom: 50px;
        @include  desktop-sm() {
            display: none;
        }
        a{
            display: block;
            color: var(--white);
            text-decoration: none;
            padding: 10px;
            border-bottom: solid 1px rgba(255,255, 255, 0.3);
            &.active{
                color: var(--primary-color);
            }
        }
    }
}
.submenu{
    position: relative;
    > a{
        padding-right: 5px;
    }
    .dwnArw{
        width: 30px;
        height: 30px;
        display: inline-block;
        background: url("../../assets/images/down_arrow_white.svg") no-repeat center center; 
        background-size: contain;
        position: absolute;
        right: 0;
        top: -5px;
        @include transition(all 0.3s ease);
    }
    ul{
        position: absolute;
        left: 0;
        top: 100%; 
        list-style: none;
        opacity: 0;
        visibility: hidden;
        margin: 0 20px;
        padding-top: 10px;
        @include transition(all 0.3s ease);
        li{
            border:  solid 1px var(--white);
            background: rgba(000, 000, 000, 0.5);
            margin-bottom: 4px;
            width: 160px;
            @include border-radius(1px);
            @include transition(all 0.3s ease);
            &:hover{
                background: var(--primary-color);
                a{
                    color: var(--white);
                }
            }
            a{
                font-size: 13px;
                color: var(--white);
                display: block;
                padding: 4px 8px;
                text-align: center;
                margin: 0;
                @include transition(all 0.3s ease);
                &.active{
                    background: var(--primary-color);
                    color: var(--white);
                }
            }
        }
    }
    &:hover{
        ul{
            opacity: 1;
            visibility: visible;
            padding-top: 20px;
        }
        .dwnArw{
            @include transform(rotate(180deg));
        }
    }
}