@mixin transform($property) {
    -webkit-transform: $property;
    -ms-transform: $property;
    transform: $property;
}

@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
}

@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin text-shadow($shadow) {
    -webkit-text-shadow: $shadow;
    -moz-text-shadow: $shadow;
    text-shadow: $shadow;
}

@mixin box-sizing($sizing) {
    -webkit-box-sizing: $sizing;
    -moz-box-sizing: $sizing;
    box-sizing: $sizing;
}


