@import "../../assets/scss/index.scss";

.testimonialItem{
    margin: 0 30px  30px;
    cursor:move;
    .testData{
        padding: 64px 24px 58px;
        background: var(--white);
        border: 0.884615px solid #777777;
        margin: 50px 0;
        position: relative;
        font-size: 16px;
        line-height: 26px;
        color: var( --textgray);
        text-align:left;
        @include box-shadow(20px 20px 0px rgba(119, 119, 119, 0.2));
        &:after {
            content: " ";
            width: 100px;
            height: 70px;
            position: absolute;
            left: 10px;
            top: -35px;
            background: url("../../assets/images/quotes_icon.png") no-repeat center center;
        }
    }
    .client{
        display: flex;
        figure{
            width:60px;
            height:60px;
            min-width: 60px;
            margin: 0 25px;
            display:block;
            overflow: hidden;
            @include border-radius(60px);
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
            @include transition(all 0.3s ease);
            img{
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }
        
        figcaption{
            text-align:left;
            align-items: flex-start;
            display:flex;
            flex-direction: column;
            justify-content:center;
            color:var(--gray);
            font-size: 12px;
            span{
                display: block;
                font-size: 18px;
                font-weight: 600;
                color:var( --gray);
                margin-bottom: 5px;
            }
        }
    }
    &:hover{
        .client{
            figure{
                filter: grayscale(0%);
                -webkit-filter: grayscale(0%);
            }
        }
    }
}