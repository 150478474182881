
.head-style-01{
    font-weight: 600;
    font-size: 34px;
    line-height:50px;
    font-weight: 600;
    color: var(--textgray);
    text-transform: uppercase;
    margin: 0 0 30px 0;
    @include  tab-sm() {
        font-size: 44px;
        line-height:60px;
    }
    &.mb-0{
        margin-bottom: 0;
    }
}

.head-style-02{
    font-size: 28px;
    line-height:38px;
    font-weight: 300;
    margin: 0 0 30px 0;
    padding-bottom: 16px;
    position: relative;
    color: var(--gray);
    &.light{
        color:var(--whitegray);
        span{
            font-weight: 600;
        }
    }
    &.center{
        text-align: center;
        &:after {
            content: "";
            width: 130px;
            height:2px;
            display: block;
            bottom: 0;
            left:calc( 50% - 65px);
            position: absolute;
        }
    }
    &:after {
        content: "";
        width: 130px;
        height:2px;
        display: block;
        bottom: 0;
        left:0;
        position: absolute;
        background: var(--primary-color );
    }
    span{
        font-weight: 600;
    }
    @include  tab-sm() {
        font-size: 35px;
        line-height:44px;
        &:after {
            height:3px;
        }
        &.large{
            font-size: 55px;
            line-height:65px;
            margin: 0 0 50px 0;
        }
    }
    &.mb-0{
        margin-bottom: 0;
    }
}


.typewriter {
    width: auto; 
    display: flex;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s infinite,
      blink-caret .75s step-end infinite;
   
  }
  .typewriter2 {
    width: auto; 
    display: flex;
    justify-content: center;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s infinite,
      blink-caret .75s step-end infinite;
   
  }
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange; }
  }